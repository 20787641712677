<template>
    <div>
        <titulo-seccion>
            <template slot="area-rutas">
                <router-link to="/">Inicio</router-link> / 
                <router-link to="/videos">Videos</router-link> / 
                <span class="active">Playlists</span>
            </template>
            <template slot="area-titulo">
                <h3 class="text-center">Playlists</h3>
            </template>
        </titulo-seccion> 
        <section class="noticias">
            <div class="container">
                <b-row>
                    <b-col
                        v-for="(item, index) in items" :key="index"
                        sm="6"
                        md="3"
                        lg="3"
                        cols="12"
                    >
                        <div class="card card-video mb-4">
                            <div class="video-portada">
                                <img 
                                    v-lazy="item.thumbnail" 
                                    class="img-fluid img-responsive cursor md-round" 
                                    :alt="item.title" 
                                    :title="item.title"
                                > 
                                <router-link class="btn btn-icon btn-play" :to="'/videos/playlists/' + item.url">
                                    <i class="icon icon-white">
                                    <svg width="10" height="10" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 0V24L20 12L0 0Z" fill="white"/>
                                    </svg>
                                    </i>
                                </router-link>
                            </div>                            
                            <b-card-text>
                                <p class="card-title text-truncate"><strong class="card-title">{{item.title}}</strong></p>
                                <div class="d-flex justify-content-between align-items-center px-2">
                                    <div>
                                        <small class="text-start text-muted text-truncate">{{item.play_list_item_count}} videos</small>
                                    </div>
                                    <div>
                                        <small class="text-start text-muted text-truncate">{{item.published_at}}</small>
                                    </div>
                                </div>
                            </b-card-text>
                        </div>        
                    </b-col>
                </b-row>
            </div>
        </section>
    </div>
</template>

<script>
import axiosIns from '@/libs/axios'
//import PageHeader from '/src/components/PageHeader.vue';
import {BCardText, BRow, BCol } from "bootstrap-vue";
import TituloSeccion from '@/views/secciones/TituloSeccion.vue'
export default {
    components:{
        //PageHeader,
        TituloSeccion,
        BCardText,
        BRow,
        BCol
    },
    directives: { 
    },
    data(){
        return{
            items:[],
        }
    },
    metaInfo: {
        title: 'Ministerio Público',
        titleTemplate: '%s | Playlists',
        htmlAttrs: {
        lang: 'es-ES'
        },
        meta: [
        { name: 'description', content: 'Playlist multimedia' }
        ]
    },
    created: function () {   
        this.listarPlaylist();
    },
    watch: {
        buscar: function(val) {
            if (val == "" || val.length == 0) {
                this.listarPlaylist();
            }
        },
    },

    methods: {
        listarPlaylist() {
            var url= '/admin/videos/playlist';
            axiosIns.get(url)
            .then(res => {
                this.items = res.data;
            })
            .catch(err =>{
                console.log(err);
            });
        },
  },

}
</script>

<style >
.cursor{
    cursor: pointer;
}
</style>
